import { useEffect, useState, useRef } from 'react';

import { initializeApp } from 'firebase/app';
import {
  Firestore,
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
  setDoc,
  getDoc,
  query,
  where,
} from 'firebase/firestore/lite';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { firebaseConfig } from '../config/firebase';

import { useAuth, useUser } from '@clerk/clerk-react';

import { sha256 } from 'hash.js';
import { SandpackBundlerFiles } from '@codesandbox/sandpack-client';
import { PROJECT_COLLECTION, VERSION_COLLECTION } from '../constants/firebase';
import { UseProjectsOptions, UserType } from '../utils/types';
import { MAX_PROJECTS } from '../constants/projects';
import { PLANS } from '../constants/user';

// Utility function to hash a project version
const stringifyAndHash = (obj: object): string => {
  const jsonString = JSON.stringify(obj);
  const hash = sha256().update(jsonString).digest('hex');
  return hash;
};

// Initialize Firebase app with the provided configuration
const app = initializeApp(firebaseConfig);

// These types correspond to documents from the projects collection
export type ProjectList = Record<string, Project>;
export type Project = {
  name: string;
  version: string;
  share: string;
};

// Function to fetch a single project from the collection
const getSingleProject = async (
  db: Firestore,
  projectId: string,
): Promise<ProjectList> => {
  const docSnap = await getDoc(doc(db, PROJECT_COLLECTION, projectId));
  return { [projectId]: docSnap.data() as Project };
};

// Function to fetch all projects for a specific user
const getUserProjects = async (
  db: Firestore,
  userId: string,
): Promise<ProjectList> => {
  // Fetch details from the collection in Firestore
  const querySnapshot = await getDocs(
    query(collection(db, PROJECT_COLLECTION), where('owner', '==', userId)),
  );
  return Object.fromEntries(
    querySnapshot.docs.map((doc) => [doc.id, doc.data() as Project]),
  );
};

export const useProjects = (
  { projectId }: UseProjectsOptions = { projectId: null },
) => {
  const [projects, setProjects] = useState<ProjectList | null>(null);
  const [error, setError] = useState<unknown>(null);
  const [loadingState, setLoadingState] = useState({
    main: true,
    update: false,
    delete: false,
  });

  const { getToken } = useAuth(); // Clerk authentication hook
  const { user } = useUser(); // Clerk user hook
  const db = getFirestore(app); // Firestore database instance
  const auth = getAuth(app); // Firebase authentication instance

  // When the component mounts, fetch the list of projects from Firebase.
  const dataFetchedRef = useRef<string | null>(null);
  const retrieveProjects = async function () {
    setLoadingState((prevState) => ({ ...prevState, main: true }));
    // Prevent repeat fetching of data for the same user
    const userId = user?.id || '';
    if (dataFetchedRef.current == userId) {
      setLoadingState((prevState) => ({ ...prevState, main: false }));
      return;
    }
    dataFetchedRef.current = userId;

    // Get the Firebase token from Clerk and sign in
    const firebaseToken = await getToken({ template: 'integration_firebase' });
    if (firebaseToken) {
      await signInWithCustomToken(auth, firebaseToken);
    }

    // If a project ID is provided, fetch only that project
    if (projectId) {
      try {
        setProjects(await getSingleProject(db, projectId));
      } catch (err: unknown) {
        console.log(err);
        setError(err);
      } finally {
        setLoadingState((prevState) => ({ ...prevState, main: false }));
      }
    } // If a user is logged in, fetch that user's projects
    else if (user && firebaseToken) {
      try {
        const fetchProjects = await getUserProjects(db, user.id);
        setProjects(fetchProjects);
      } catch (err: unknown) {
        console.log(err);
        setError(err);
      } finally {
        setLoadingState((prevState) => ({ ...prevState, main: false }));
      }
    } // Otherwise, clear the projects list
    else {
      setProjects(null);
      setLoadingState((prevState) => ({ ...prevState, main: false }));
    }
  };

  // When the page loads or user changes, fetch the list of projects from Firebase.
  useEffect(() => {
    retrieveProjects();
  }, [user]);

  useEffect(() => {
    retrieveProjects();
  }, []);

  useEffect(() => {
    if (projects !== null && loadingState.main) {
      setLoadingState((prevState) => ({ ...prevState, main: false }));
    }
  }, [projects]);

  // Function to update or create a collection item
  const updateProject = async (
    id: string | null,
    updateData: Partial<Project>,
    files?: SandpackBundlerFiles,
  ): Promise<string | null> => {
    // Don't make changes if the projects list is still loading
    if (loadingState.main) {
      console.log(
        'Update operation cancelled. Projects list is still loading.',
      );
      return null;
    }
    setLoadingState((prevState) => ({ ...prevState, update: true }));
    try {
      // Get the Firebase token from Clerk authentication
      const firebaseToken = await getToken({
        template: 'integration_firebase',
      });

      if (firebaseToken && user) {
        // Sign in to Firebase using the custom token
        await signInWithCustomToken(auth, firebaseToken);

        // Create a new version if files are provided.
        let versionId = null;
        if (files) {
          const hash = stringifyAndHash(files);
          const projectRef = doc(db, VERSION_COLLECTION, hash);
          const docSnapshot = await getDoc(projectRef);
          if (!docSnapshot.exists()) {
            await setDoc(projectRef, { files });
          }
          versionId = hash;
        }

        // Update the project with the new data
        const data = {
          ...updateData,
          owner: user.id,
          ...(versionId && { version: versionId }),
        };

        if (id && projects) {
          // Update the specified document in the collection
          const projectRef = doc(db, PROJECT_COLLECTION, id);
          await updateDoc(projectRef, data);

          // Update the local state with the updated project
          setProjects(
            Object.assign({}, projects, {
              [id]: Object.assign({}, projects[id], data),
            }),
          );

          return id;
        } else {
          // Create a new document in the collection
          const newDocRef = await addDoc(
            collection(db, PROJECT_COLLECTION),
            data,
          );
          const newId = newDocRef.id;

          // Update the local state with the new project
          setProjects(Object.assign({}, projects, { [newId]: data }));

          return newId;
        }
      }
    } catch (err: unknown) {
      // Handle any errors that occur during the update process
      console.log(err);
      setError(err);
    } finally {
      setLoadingState((prevState) => ({ ...prevState, update: false }));
    }

    return null;
  };

  // Delete a project from the collection
  const deleteProject = async (id: string): Promise<void> => {
    setLoadingState((prevState) => ({ ...prevState, delete: true }));
    try {
      // Get the Firebase token from Clerk authentication
      const firebaseToken = await getToken({
        template: 'integration_firebase',
      });

      if (firebaseToken) {
        // Sign in to Firebase using the custom token
        await signInWithCustomToken(auth, firebaseToken);

        // Delete the specified document from the collection
        const projectRef = doc(db, PROJECT_COLLECTION, id);
        await deleteDoc(projectRef);

        // Update the local state by removing the deleted project
        const updatedProjects = { ...projects };
        delete updatedProjects[id];
        setProjects(updatedProjects);
      }
    } catch (err: unknown) {
      // Handle any errors that occur during the delete process
      console.log(err);
      setError(err);
    } finally {
      setLoadingState((prevState) => ({ ...prevState, delete: false }));
    }
  };

  // Retrieve the files for a specific version ID
  const getVersion = async (
    id: string,
  ): Promise<SandpackBundlerFiles | null> => {
    try {
      const projectRef = doc(db, VERSION_COLLECTION, id);
      const docSnap = await getDoc(projectRef);
      const data = docSnap.data();
      if (data) {
        return data.files;
      }
    } catch (err: unknown) {
      // Handle any errors that occur during the delete process
      console.log(err);
      setError(err);
    }
    return null;
  };

  // Return the fetched details, error state, and update function
  return {
    projects,
    error,
    updateProject,
    deleteProject,
    getVersion,
    loadingState,
  };
};

export function useNewProjectAllowed(
  projects: ProjectList | null,
  user: UserType | null,
) {
  const [isNewProjectAllowed, setIsNewProjectAllowed] = useState(false);

  useEffect(() => {
    const projectsIds = Object.keys(projects ?? {});
    const isNewProjectAllowed =
      (!!projects && projectsIds.length < MAX_PROJECTS.FREE_USER) ||
      user?.plan === PLANS.PRO;
    setIsNewProjectAllowed(isNewProjectAllowed);
  }, [projects, user]);

  return isNewProjectAllowed;
}
