import { Modal } from 'flowbite-react';
import React, { useRef, useEffect } from 'react';
import { ToolbarButton } from './ToolbarButton';

type PopupProps = {
  children: React.ReactNode; // The content of the modal
  label: React.ReactNode; // The label of the button that opens the modal
  disabled?: boolean; // Whether the button is disabled
  open?: boolean; // Whether the modal is open
  callback?: (open: boolean) => void; // Callback to open or close the modal
  Icon?: React.ReactNode;
};

export const Popup = ({
  open,
  label,
  children,
  disabled = false,
  callback: callback = () => {},
  Icon,
}: PopupProps) => {
  // Reference to the modal element
  const modalRef = useRef<HTMLDivElement>(null);

  // Event listeners to handle closing the modal when pressing ESC key or clicking outside the modal
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && open) {
        callback(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listeners when the component is unmounted
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  return (
    <>
      {/* Button to open the modal */}
      <ToolbarButton
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          callback(true);
          return false;
        }}
        className="mr-2 rounded bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        disabled={disabled}
        label={label}
        Icon={Icon}
      />

      {/* Modal component */}
      {open && (
        <Modal
          key="popup"
          show={true}
          size="3xl"
          popup
          onClose={() => callback(false)}
          ref={modalRef}
          dismissible
        >
          <Modal.Header />
          <Modal.Body>{children}</Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Popup;
