import { useState, useEffect, useRef, Ref } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { v4 as uuidv4 } from 'uuid';
import { useClerk } from '@clerk/clerk-react';

// Sandpack imports
import {
  SandpackFileExplorer,
  SandpackPreview,
  useSandpack,
  CodeEditorRef,
} from '@codesandbox/sandpack-react';
import projectTemplate from '../sandpack/projectTemplate';

// Clerk imports
import { useAuth } from '@clerk/clerk-react';

// Components
import { Editor } from './Editor';
import { CommandBar } from '../components/CommandBar';
import { ToolBar, ProjectContext } from '../components/Toolbar';

// Hooks
import { useTimer } from '../hooks/timer';
import { useNewProjectAllowed, useProjects } from '../hooks/projects';
import { useApplyTransformation } from '../hooks/applyTransformation';
import { useStaticTransformation } from '../hooks/staticTransformation';
import { useUserCookie } from '../hooks/userCookie';
import { useUndoManager, UndoManager } from '../hooks/undoManager';

import { loadFiles } from '../sandpack/loadFiles';
import { useFirebaseUser } from '../hooks/user';
import {
  blueButtonStyles,
  buttonStyles,
  inlineIconStyles,
} from '../constants/toolbar';
import { FaWandMagicSparkles } from 'react-icons/fa6';
import { Button } from './shared/Button';
import { GrUndo } from 'react-icons/gr';
import { UserType } from '../utils/types';
import { OverlayLoader } from './shared/OverlayLoader';

export const ProjectEditor = function ({
  urlProjectId,
  initialPrompt,
}: {
  urlProjectId: string | null;
  initialPrompt: string | null;
}) {
  // This gives us function to interact with the editor file tree.
  const { sandpack } = useSandpack();
  const { user, loading: isFirebaseUserLoading } = useFirebaseUser();
  const undoManager: UndoManager = useUndoManager();
  const [commandBarOpen, setCommandBarOpen] = useState<boolean>(false);
  const { firstLaunch } = useUserCookie();
  const [currentProjectId, setCurrentProjectId] = useState<string | null>(null);
  const editorRef: Ref<CodeEditorRef> = useRef<CodeEditorRef>(null);
  const [selectedComponentName, setSelectedComponentName] = useState<
    string | null
  >(null);
  const [isNewProject, setIsNewProject] = useState<boolean>(false);
  const { openSignIn, user: clerkUser } = useClerk();
  const userSignedIn: boolean = !!clerkUser && !!clerkUser.id;

  // Get the project list from the API.
  const {
    projects,
    updateProject,
    deleteProject,
    getVersion,
    loadingState: { main: projectsLoading },
  } = useProjects({
    projectId: null,
  });

  const isNewProjectAllowed = useNewProjectAllowed(projects, user);

  // Get the project name from the project list.
  const projectName =
    currentProjectId && projects && projects[currentProjectId]
      ? projects[currentProjectId].name
      : 'Untitled project';

  // Load the project in the editor when the project list is loaded.
  useEffect(() => {
    // If the current URL contains a valid project ID, load that project.
    if (projects) {
      console.log(`Loading ${urlProjectId} from URL.`);
      if (urlProjectId) {
        if (projects[urlProjectId]) {
          loadProject(urlProjectId);
        } else {
          navigate(`/`);
        }
      } else {
        setIsNewProject(true);
      }
    }
  }, [projects]);

  const { getToken, sessionId } = useAuth();

  useEffect(() => {
    if (initialPrompt) {
      applyTransformation(initialPrompt);
    }
  }, [initialPrompt]);

  // Create a timer that waits one second to save the project.
  const saveProject = async (name: string = projectName) => {
    if (projects) {
      // The user is signed in and the projects list is loaded.
      if (!currentProjectId && !isNewProjectAllowed) {
        return;
      }
      // Local storage project IDs are 8 characters long.
      const isLocalId = currentProjectId && currentProjectId?.length <= 8;
      // Ignore the project if it's a local project and the user is signed in.
      const newProjectId = await updateProject(
        isLocalId ? null : currentProjectId,
        { name },
        sandpack.files,
      );
      if (newProjectId) {
        setCurrentProjectId(newProjectId);
        navigate(`/project/${newProjectId}`);
      }
    } else if (!sessionId) {
      // The user is signed out.
      let projectId = currentProjectId;
      // If the project ID is not set, generate a new one.
      if (!currentProjectId) {
        projectId = uuidv4().split('-')[0];
        setCurrentProjectId(projectId);
        navigate(`/project/${projectId}`);
      }
      // Save the project to local storage.
      localStorage.setItem(
        `project_${projectId}`,
        JSON.stringify(sandpack.files),
      );
    }
  };
  const { startTimer: startSaveTimer } = useTimer(1000, saveProject);

  useEffect(() => {
    // When the user signs out, clear the current project.
    if (currentProjectId && (!projects || !projects[currentProjectId])) {
      newProject();
    }
  }, [projects]);

  // Start the timer when the editor state changes.
  useEffect(() => {
    // Ensure that the page is finished loading before saving.
    startSaveTimer();
  }, [sandpack.files]);

  // Delete any default files that are not in the custom template.
  useEffect(() => {
    for (const filePath in sandpack.files) {
      if (!Object.keys(projectTemplate.files).includes(filePath)) {
        // Only delete files in the root directory.
        if (filePath.split('/').length <= 2) {
          sandpack.deleteFile(filePath);
        }
      }
    }
  }, [sandpack.deleteFile]);

  // Apply the transformation when the user presses enter in the command bar.
  const [applyTransformation, fixDependencies, loading, loadingProgress] =
    useApplyTransformation({
      editorRef,
      getToken,
      onStart: () => {
        undoManager.pushState(sandpack.files);
      },
      onFinish: startSaveTimer,
    });

  const { moveSelectedComponentToNewFile, getSelectedComponentName } =
    useStaticTransformation({
      sandpack,
      editorRef,
      onStart: () => {
        undoManager.pushState(sandpack.files);
      },
    });

  // Load the project and close the popup.
  const navigate = useNavigate();
  const loadProject = async (projectId: string | null) => {
    if (projectId == null || projectId == currentProjectId) {
      return;
    }
    console.log(`Loading ${projectId}.`);
    setCurrentProjectId(projectId);
    undoManager.reset();

    if (projectId && projects) {
      // Load the project files.
      const loadedFiles = await getVersion(projects[projectId]['version']);
      if (loadedFiles) {
        loadFiles(loadedFiles, sandpack);
        // When opening a project, set the URL to the project ID.
        navigate(`/project/${projectId}`);
      } else {
        navigate(`/`);
      }
    }
  };

  const newProject = async () => {
    sandpack.resetAllFiles();
    setCurrentProjectId(null);
    undoManager.reset();
    navigate(`/`);
  };

  const previewProps = {
    style: {
      height: '100%',
    },
    showOpenInCodeSandbox: false,
    showSandpackErrorOverlay: false,
  };

  const extractFileName = (inputString: string) =>
    (inputString.match(/[^/]+$/) || [])[0] || inputString;
  const activeFileName = extractFileName(sandpack.activeFile);

  return (
    <OverlayLoader
      className="absolute z-50 h-100vh w-100vw"
      loading={projectsLoading || isFirebaseUserLoading}
      overlay={false}
      tip="Setting up the playground 🚀 for you..."
    >
      <ProjectContext.Provider
        value={{
          projectName,
          currentProjectId,
          newProject,
          loadProject,
          projects,
          deleteProject,
          setCommandBarOpen: (open: boolean) => {
            // Get the name of the first selected React component definition in the code editor.
            // This is clearly a non-reactive pattern. It's necessary because the selection range is accessed through a ref.
            setSelectedComponentName(getSelectedComponentName());
            setCommandBarOpen(open);
          },
          loading,
          updateProject,
          saveProject,
          firstLaunch,
          undoManager,
          plan: user?.plan,
          isNewProjectAllowed: isNewProjectAllowed,
          projectsLoading: projectsLoading,
          isFirebaseUserLoading: isFirebaseUserLoading,
          firebaseUser: user as UserType,
          isNewProject: isNewProject,
        }}
      >
        <CommandBar
          callback={(commandId?: string | null, query?: string) => {
            if (commandId === 'ADDCOMPONENT') {
              moveSelectedComponentToNewFile();
            } else if (commandId === 'FIXDEPENDENCIES') {
              fixDependencies();
            } else if (query) {
              applyTransformation(query);
            }
            setCommandBarOpen(false);
          }}
          commands={(query?: string) =>
            query
              ? [
                  ...('detect and install dependencies'.includes(
                    query.toLowerCase(),
                  )
                    ? [
                        {
                          id: 'FIXDEPENDENCIES',
                          name: 'Detect and install dependencies',
                        },
                      ]
                    : []),
                  { id: 'TRANSFORM', name: `${query}` },
                  ...(selectedComponentName
                    ? [
                        {
                          id: 'ADDCOMPONENT',
                          name: `Move ${selectedComponentName} to components/${selectedComponentName}.js`,
                        },
                      ]
                    : []),
                ]
              : [
                  { name: 'Add a top bar with icons' },
                  { name: 'Add a footer' },
                  { name: 'Add a sidebar' },
                  { name: 'Add dark mode' },
                  { name: 'Add a form with three text fields' },
                  { name: 'Add a chart' },
                  { name: 'Add a table' },
                  { name: 'Add an image gallery' },
                  { name: 'Add a webcam view' },
                  { name: 'Add toast notifications' },
                  { name: 'Load a post from typicode.com' },
                  { name: 'Load news from rss.nytimes.com' },
                  { name: 'Restyle in the theme of Apple' },
                  { name: 'Restyle in the theme of Google' },
                  { name: 'Restyle in the theme of Spotify' },
                  { name: 'Restyle in the theme of Facebook' },
                  { name: 'Restyle in the theme of Twitter' },
                  { name: 'Fix padding and spacing' },
                  { name: 'Add animations' },
                ]
          }
          open={commandBarOpen && !loading}
          filename={activeFileName}
        />
        <ToolBar />
        <div className="flex flex-col lg:flex-row h-[calc(100vh-50px)]">
          <div className="hidden lg:block lg:w-1/5 border border-slate-200">
            <SandpackFileExplorer
              style={{
                height: '100%',
              }}
            />
          </div>
          <div className="w-full lg:w-2/5 border-t border-b border-slate-200 relative">
            <div className="absolute px-1 mr-1.5 flex justify-end items-end w-full">
              {/* Undo button */}
              {!loading && undoManager.history.length !== 0 && (
                <Button
                  onClick={undoManager.undo}
                  className={`${buttonStyles} z-10 mt-2 mr-2`}
                  label={<span className="hidden tablet:inline">Undo</span>}
                  Icon={
                    <GrUndo
                      className={`tablet:mr-2 mr-0 ${inlineIconStyles}`}
                    />
                  }
                  disabled={loading}
                />
              )}
              {/* Transform button */}
              <Button
                variant="primary"
                onClick={() => {
                  userSignedIn ? setCommandBarOpen?.(true) : openSignIn();
                }}
                className={`${blueButtonStyles} z-10 mt-2 mr-2`}
                label={
                  <span className="inline">
                    {loading ? (
                      <AiOutlineLoading3Quarters
                        className={`animate-spin inline mr-2 stroke-white`}
                        color="white"
                      />
                    ) : (
                      <FaWandMagicSparkles
                        className={`inline mr-2 stroke-white ${inlineIconStyles}`}
                        color="white"
                      />
                    )}
                    Transform
                  </span>
                }
                disabled={loading}
              />
              {/* Fix button */}
              {sandpack.error && !loading && (
                <Button
                  onClick={() => {
                    userSignedIn
                      ? applyTransformation(
                          'Fix error: ' + sandpack.error?.message,
                        )
                      : openSignIn();
                  }}
                  className={`${buttonStyles} z-10 mt-2 mr-2`}
                  label={
                    <span className="inline">
                      <FaWandMagicSparkles
                        className={`inline mr-2 ${inlineIconStyles}`}
                      />
                      Fix
                    </span>
                  }
                  disabled={loading}
                />
              )}
            </div>
            <Editor
              history={undoManager.history}
              loading={loading}
              loadingProgress={loadingProgress ?? 0}
              editorRef={editorRef}
            />
          </div>
          <div className="w-full lg:w-2/5 border border-slate-200">
            <SandpackPreview key="preview" {...previewProps} />
          </div>
        </div>
      </ProjectContext.Provider>
    </OverlayLoader>
  );
};
