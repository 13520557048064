import { useEffect } from 'react';

type ShortcutConfig = {
  key: string;
  shift?: boolean;
  alt?: boolean;
  callback: () => void;
};

export function useKeyboardShortcut(shortcuts: ShortcutConfig[]) {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      for (const shortcut of shortcuts) {
        if (
          event.key === shortcut.key &&
          event.shiftKey === !!shortcut.shift &&
          event.altKey === !!shortcut.alt &&
          event.metaKey
        ) {
          event.preventDefault(); // Prevent the default action
          shortcut.callback();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [shortcuts]);
}
