import { SignedIn, useClerk } from '@clerk/clerk-react';
import { Button, ButtonSize } from './shared/Button';
import { getBillingPortalURL } from '../utils';
import { BsLightningFill } from 'react-icons/bs';
import { ProjectContext } from './Toolbar';
import { useContext } from 'react';
import { ProjectContextType } from '../utils/types';

interface UpgradeButtonProps {
  size?: ButtonSize;
  onClick?: () => void;
}

export const UpgradeButton: React.FC<UpgradeButtonProps> = ({
  size = 'medium',
  onClick,
}) => {
  const { user } = useClerk();
  const { isFirebaseUserLoading, firebaseUser } =
    useContext<ProjectContextType>(ProjectContext);
  const handleUpgradeClick = () => {
    const billingPortalURL = getBillingPortalURL(
      user?.emailAddresses?.[0]?.emailAddress,
    );
    window.open(billingPortalURL, '_self');
  };

  if (isFirebaseUserLoading || !firebaseUser) {
    return null;
  }

  return (
    <SignedIn>
      <Button
        label="Upgrade to Pro"
        onClick={onClick || handleUpgradeClick}
        variant="primary"
        className="mr-2"
        Icon={<BsLightningFill />}
        iconClasses="!mr-1"
        size={size}
      />
    </SignedIn>
  );
};
