// Sandpack imports
import { SandpackProvider } from '@codesandbox/sandpack-react';
import projectTemplate from './sandpack/projectTemplate';

// Hooks
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import fetchTemplate from './hooks/fetchTemplate';

// Components
import { ProjectPreview } from './components/ProjectPreview';
import { ProjectEditor } from './components/ProjectEditor';
import Spinner from './components/Spinner';

// The project editor page
export default function ProjectEditorPage() {
  // Get the project ID from the URL.
  const { pathname } = useLocation();
  const parts = pathname.split('/').filter((part) => part !== '');
  const page = parts[0];
  const isTemplate = page === 'template';
  const isSignInRedirect = page === 'signin';
  const urlProjectId = isTemplate ? null : parts[1];

  const [files, setFiles] = useState(projectTemplate.files);
  const [filesLoaded, setFilesLoaded] = useState(false);
  const [initialPrompt, setInitialPrompt] = useState<string | null>(null);

  useEffect(() => {
    if (isTemplate) {
      const templateName = parts[1];

      // Parse the 'variables' query parameter as JSON, if it exists
      let jsonData: Record<string, string> | null = null;
      try {
        const url = new URL(window.location.href);
        const queryParams = new URLSearchParams(url.search);
        const variablesParam = queryParams.get('variables');
        if (variablesParam) {
          jsonData = JSON.parse(variablesParam);
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }

      if (jsonData && jsonData['VIEW_TYPE'] === 'Table') {
        setInitialPrompt('Update the table to match the schema');
      }
      if (jsonData && jsonData['VIEW_TYPE'] === 'List') {
        setInitialPrompt('Convert the table to a list view');
      }
      if (jsonData && jsonData['VIEW_TYPE'] === 'Cards') {
        setInitialPrompt('Convert the table to a grid card view');
      }

      (async () => {
        const newFiles = await fetchTemplate(templateName);
        // Replace any <VARIABLE> in the files with the values from the query parameter
        if (jsonData !== null) {
          Object.keys(jsonData).forEach((key) => {
            const value = jsonData![key];
            Object.keys(newFiles).forEach((file) => {
              const newFile = newFiles[file].replace(`<${key}>`, value);
              newFiles[file] = newFile;
            });
          });
        }
        setFiles({ ...files, ...newFiles });
        setFilesLoaded(true);
      })();
    }

    if (isSignInRedirect) {
      const storedData = localStorage.getItem(`project_${urlProjectId}`);
      setFiles(JSON.parse(storedData ?? '{}'));
      setFilesLoaded(true);
    }
  }, []);

  return (!isTemplate && !isSignInRedirect) || filesLoaded ? (
    <>
      <SandpackProvider
        template="react"
        files={files}
        options={{
          ...projectTemplate.options,
          recompileMode: 'delayed',
          recompileDelay: 1000,
        }}
      >
        {page === 'run' ? (
          // Show the deployed project with no editor.
          <ProjectPreview urlProjectId={urlProjectId} />
        ) : (
          // Show the editor.
          <ProjectEditor
            urlProjectId={urlProjectId}
            initialPrompt={initialPrompt}
          />
        )}
      </SandpackProvider>
    </>
  ) : (
    <div className="flex h-screen w-screen items-center justify-center">
      <Spinner />
    </div>
  );
}
