import React, { ReactNode } from 'react';
import { Modal } from 'flowbite-react';
import { HiCheck } from 'react-icons/hi';
import { UpgradeButton } from './UpgradeButton';

interface FeatureItemProps {
  text: string;
  checkIcon: ReactNode;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ text, checkIcon }) => {
  return (
    <div className="flex items-center ml-2">
      {checkIcon}
      <span className="text-md ml-2 font-semibold">{text}</span>
    </div>
  );
};

interface ModalHeaderProps {
  title: string;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ title }) => {
  return <h1 className="text-xl font-semibold">{title}</h1>;
};

interface ModalContentProps {
  isNewProjectAllowed: boolean;
}

const ModalContent: React.FC<ModalContentProps> = ({ isNewProjectAllowed }) => {
  const check = <HiCheck className="h-7 w-7 text-green-500 mt-1" />;
  return (
    <>
      <p className="mb-5 ml-2">
        {isNewProjectAllowed
          ? 'Upgrading to GitWit Pro allows you to build and launch an unlimited number of projects.'
          : "You've reached your free project limit of 3/3 projects. By upgrading to GitWit Pro, you can continue to build and launch projects."}
      </p>
      <div className="grid grid-cols-2 gap-4 px-1">
        <div className="px-4 pt-4 pb-8 bg-gray-100 rounded-lg">
          <div className="text-xl font-bold text-center mb-4">
            <ModalHeader title="Free" />
            <span className="text-blue-800">$0/month</span>
          </div>
          <FeatureItem text="Unlimited generations" checkIcon={check} />
          <FeatureItem text="Up to three projects" checkIcon={check} />
        </div>
        <div className="px-4 pt-4 pb-8 bg-blue-100 rounded-lg">
          <div className="text-xl font-bold text-center mb-4">
            <ModalHeader title="Pro" />
            <span className="text-blue-800">$12/month</span>
          </div>
          <FeatureItem text="Unlimited generations" checkIcon={check} />
          <FeatureItem text="Unlimited projects" checkIcon={check} />
          <FeatureItem text="Unlimited deployments" checkIcon={check} />
        </div>
      </div>
    </>
  );
};

const ModalFooter: React.FC = () => {
  return (
    <>
      <div className="flex justify-center mt-6">
        <UpgradeButton size="large" />
      </div>
      <div className="text-center mt-4 text-gray-500">
        For help with subscriptions, please email{' '}
        <a href="mailto:contact@gitwit.dev">contact@gitwit.dev</a>.
      </div>
    </>
  );
};

interface UpgradeModalProps {
  open: boolean;
  onClose: () => void;
  isNewProjectAllowed: boolean;
}

const UpgradeModal: React.FC<UpgradeModalProps> = ({
  open,
  onClose,
  isNewProjectAllowed,
}) => {
  if (!open) return null;

  return (
    <Modal
      key="upgrade-modal"
      dismissible
      show={open}
      size="3xl"
      popup
      onClose={onClose}
    >
      <Modal.Header>
        <h1 className="mt-2 ml-3 text-xl font-semibold">
          Upgrade for unlimited projects!
        </h1>
      </Modal.Header>
      <Modal.Body className="p-4">
        <ModalContent isNewProjectAllowed={isNewProjectAllowed} />
        <ModalFooter />
      </Modal.Body>
    </Modal>
  );
};

export default UpgradeModal;
