import { useState, useEffect, RefObject } from 'react';
import {
  CodeEditorRef,
  SandpackState,
  useSandpack,
  useActiveCode,
} from '@codesandbox/sandpack-react';
import { EditorSelection } from '@codemirror/state';
import { sleep } from '../utils';
import { useUserCookie } from './userCookie';

const mergeTextBlocks = (text1: string, text2: string) => {
  const lines1 = text1.split('\n');
  const lines2 = text2.split('\n');
  const mergedLines = [...lines1]; // Copy all lines from text1
  mergedLines.push(...lines2.slice(lines1.length)); // Append extra lines from text2
  return mergedLines.join('\n');
};

const addDependencies = (
  dependencies: { [key: string]: string },
  sandpack: SandpackState,
) => {
  const packageJson = JSON.parse(sandpack.files['/package.json'].code);
  for (const dependency in dependencies) {
    if (packageJson.dependencies[dependency] === undefined) {
      packageJson.dependencies[dependency] = dependencies[dependency];
    }
  }
  sandpack.updateFile('/package.json', JSON.stringify(packageJson, null, 2));
  // I don't know why this line is necessary, but it is:
  sandpack.files['/package.json'].code = JSON.stringify(packageJson, null, 2);
};

type ApplyTransformationType = (command: string) => void;
type FixDependenciesType = () => void;

type ApplyTransformationOptions = {
  getToken: () => Promise<string | null>;
  editorRef: RefObject<CodeEditorRef>;
  onStart: () => void;
  onFinish: () => void;
};

type UseApplyTransformationType = (
  options: ApplyTransformationOptions,
) => [ApplyTransformationType, FixDependenciesType, boolean, number];

const apiBaseURL =
  process.env.REACT_APP_SANDCASTLE_API_URL ||
  'https://sandcastle-backend.onrender.com/';

export const useApplyTransformation: UseApplyTransformationType = ({
  editorRef,
  getToken,
  onStart,
  onFinish,
}) => {
  const { sandpack } = useSandpack();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const { code, updateCode } = useActiveCode();
  const [scrollTo, setScrollTo] = useState<number | null>(null);
  const { userId } = useUserCookie();

  const applyTransformation: ApplyTransformationType = async (command) => {
    try {
      setLoadingProgress(0);
      setLoading(true);
      const requestData = {
        command: command,
        code,
        userId,
      };
      const token = await getToken();
      const response = await fetch(apiBaseURL + 'generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok && response.body) {
        onStart();

        const reader = response.body.getReader();
        let newCode = '';
        let dependencies = [];

        let streamedContent = '';
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }

          // Accumulate streamed content until a newline is encountered.
          streamedContent += new TextDecoder().decode(value);
          if (!streamedContent.includes('\n')) {
            continue;
          }

          // Each line is a JSON message.
          const messages = streamedContent
            .substring(0, streamedContent.lastIndexOf('\n'))
            .split('\n')
            .filter((m) => m !== '');
          streamedContent = streamedContent.substring(
            streamedContent.lastIndexOf('\n'),
          );

          // Parse each new message.
          let codeChanged = false;
          let dependenciesChanged = false;
          for (const rawMessage of messages) {
            try {
              const message = JSON.parse(rawMessage);
              if (message.type === 'text') {
                newCode = newCode + message.content;
                codeChanged = true;
              } else if (message.type === 'dependencies') {
                dependencies = message.content;
                dependenciesChanged = true;
              }
            } catch (error) {
              console.error('Failed to parse stream JSON:' + rawMessage);
            }
            if (codeChanged) {
              updateCode(mergeTextBlocks(newCode, code));
              setScrollTo(newCode.length);
            }
            if (dependenciesChanged) addDependencies(dependencies, sandpack);

            // Estimate progress using the length of the old and new code blocks.
            // This function approaches 1 asymptotically as x increases.
            const progressEstimator = (x: number) =>
              Math.atan(x * 3) * (2 / Math.PI);
            setLoadingProgress(
              100 * progressEstimator(newCode.length / code.length),
            );
          }
        }

        // Because updateFile is not atomic, wait a bit to ensure previous updates have finished.
        setLoadingProgress(100);
        await sleep(250);
        // If the new code is blank, revert to the old code.
        if (newCode === '') {
          alert('Please try again or rephrase your command.');
        }
        // Update the editor a final time with the complete generated code.
        if (code !== newCode) {
          updateCode(newCode);
          setScrollTo(newCode.length);
        }

        setLoading(false);

        // Save the project after a transformation.
        onFinish();
      } else {
        setLoading(false);

        // Pass the server error message to the user.
        const reponseData = await response.json();
        alert(reponseData['error']);
        console.error('Error fetching data:', response.statusText);
      }
    } catch (error) {
      setLoading(false);
      alert(error);
      console.error('Error fetching data:', error);
    }
  };

  const fixDependencies: FixDependenciesType = async () => {
    const requestData = {
      code,
      userId,
    };
    const result = await fetch(apiBaseURL + 'dependencies', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });
    const dependencies = await result.json();
    addDependencies(dependencies, sandpack);
  };

  // This effect waits until the code finishes updating, then scrolls to the right spot.
  // This is needed because updateFile's behavior is not atomic.
  const scrollIfNeeded = () => {
    (async () => {
      if (code && scrollTo !== null) {
        const cmInstance = editorRef.current?.getCodemirror();
        if (!cmInstance) return;
        cmInstance.update([
          cmInstance.state.update({
            selection: EditorSelection.cursor(scrollTo),
            scrollIntoView: true,
          }),
        ]);
        setScrollTo(null);
      }
    })();
  };
  useEffect(scrollIfNeeded, [code, scrollTo]);

  return [applyTransformation, fixDependencies, loading, loadingProgress];
};
