import { useState } from 'react';

import { useSandpack } from '@codesandbox/sandpack-react';

import { deepCopy } from '../utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useUndoManager<SandpackState extends Record<string, any>>() {
  type History = SandpackState['files'];
  type HistoryStack = Array<History>;

  const { sandpack } = useSandpack();
  const [history, setHistory] = useState<HistoryStack>([]);

  // Function to push the current state into the history stack
  const pushState = (state: History) => {
    setHistory((prevHistory) => [...prevHistory, deepCopy(state)]);
  };

  const reset = () => {
    setHistory([]);
  };

  // Undo the last transformation.
  const undo = () => {
    if (history.length > 0) {
      const previousState = history[history.length - 1];
      setHistory(history.slice(0, history.length - 1));
      if (previousState) {
        if (previousState[sandpack.activeFile] === undefined) {
          sandpack.setActiveFile('/src/App.js');
        }
        sandpack.resetAllFiles();
        for (const filePath in previousState) {
          sandpack.updateFile(filePath, previousState[filePath].code);
        }
      }
    }
  };

  return {
    pushState,
    undo,
    history,
    reset,
  };
}

export type UndoManager = ReturnType<typeof useUndoManager>;
