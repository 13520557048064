import React, { useState } from 'react';
import {
  HiOutlineClipboardCopy,
  HiOutlineClipboardCheck,
} from 'react-icons/hi';
import { Switch } from '@headlessui/react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type ProjectOptions = {
  share: string;
};

type UpdateProjectCallback = (updateData: Partial<ProjectOptions>) => void;

type ShareViewProps = {
  projectId: string;
  onProjectUpdated: UpdateProjectCallback;
  projectData: Record<string, never>;
};

export default function ShareView({
  projectId,
  onProjectUpdated,
  projectData,
}: ShareViewProps) {
  const [copySuccess, setCopySuccess] = useState(false);

  // Permalink to a deployed project
  const projectURL = `https://react.gitwit.dev/run/${projectId}`;

  // Copy the permalink to the clipboard
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(projectURL);
      setCopySuccess(true);
    } catch (err) {
      console.error('Failed to copy to clipboard: ', err);
      setCopySuccess(false);
    }
  };

  const [enabled, setEnabled] = useState(projectData?.share == 'public');

  return (
    <div className="px-2 pb-2">
      <h3 className="text-2xl font-medium text-gray-900 dark:text-white mb-6">
        Sharing Settings
      </h3>

      <Switch.Group as="div" className="flex items-center">
        <Switch
          checked={enabled}
          onChange={(checked) => {
            setEnabled(checked);
            onProjectUpdated({ share: checked ? 'public' : 'private' });
          }}
          className={classNames(
            enabled ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          />
        </Switch>
        <Switch.Label as="span" className="ml-3 text-sm">
          <span className="font-medium text-gray-800">
            Make this project public
          </span>{' '}
        </Switch.Label>
      </Switch.Group>

      <div className="grid grid-cols-2 gap-2 mt-4">
        <div>
          <label htmlFor="email" className="sr-only">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            readOnly={true}
            className="block w-full rounded-md border-0 py-1.5 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={projectURL}
          />
        </div>
        <div>
          <button
            onClick={copyToClipboard}
            className="bg-gray-200 text-black rounded-md px-4 py-2 ring-inset"
          >
            {copySuccess ? (
              <HiOutlineClipboardCheck size="20" />
            ) : (
              <HiOutlineClipboardCopy size="20" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
