import { SandpackBundlerFiles } from '@codesandbox/sandpack-client';
import { SandpackState } from '@codesandbox/sandpack-react';

// Load the project files into the editor.
export const loadFiles = async (
  loadedFiles: SandpackBundlerFiles,
  sandpack: SandpackState,
) => {
  // Reset the editor state.
  sandpack.resetAllFiles();
  // Load the project files.
  for (const filePath in loadedFiles) {
    sandpack.updateFile(filePath, loadedFiles[filePath].code);
  }
};
