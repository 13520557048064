import {
  useClerk,
  SignInButton,
  SignedIn,
  SignedOut,
} from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import { useSandpack } from '@codesandbox/sandpack-react';

import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { GrLogin, GrLogout } from 'react-icons/gr';

function SignOutButton({
  className,
}: DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) {
  const navigate = useNavigate();
  const { signOut } = useClerk();
  const { sandpack } = useSandpack();

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
      sandpack.resetAllFiles();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };
  return (
    <button onClick={handleSignOut} className={className}>
      <GrLogout className="inline mr-1 -translate-y-0.5" />{' '}
      <span className="inline">Sign Out</span>
    </button>
  );
}

export function SignInOutButton({
  className,
  afterSignInUrl = '/',
}: DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  afterSignInUrl?: string;
}) {
  return (
    <>
      <SignedIn>
        <SignOutButton className={className} />
      </SignedIn>
      <SignedOut>
        <SignInButton
          mode="modal"
          afterSignInUrl={afterSignInUrl}
          afterSignUpUrl={afterSignInUrl}
        >
          <button className={className}>
            <GrLogin className="inline mr-1 -translate-y-0.5" />{' '}
            <span className="inline">Sign In</span>
          </button>
        </SignInButton>
      </SignedOut>
    </>
  );
}
