import React, {
  ButtonHTMLAttributes,
  AnchorHTMLAttributes,
  ReactNode,
} from 'react';
import { DeepPartial, FlowbiteTooltipTheme, Tooltip } from 'flowbite-react';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'alert'
  | 'danger'
  | 'tertiary';
type IconPosition = 'left' | 'right';
export type ButtonSize = 'small' | 'medium' | 'large';
export type Alignment = 'start' | 'end';
export type Side = 'top' | 'right' | 'bottom' | 'left';
export type AlignedPlacement = `${Side}-${Alignment}`;
export type Placement = Side | AlignedPlacement;
type TooltipProps = {
  animation?: false | `duration-${number}`;
  arrow?: boolean;
  content: ReactNode;
  placement?: 'auto' | Placement;
  style?: 'dark' | 'light' | 'auto';
  theme?: DeepPartial<FlowbiteTooltipTheme>;
  trigger?: 'hover' | 'click';
};

type CommonProps = {
  Icon?: React.ReactNode;
  label: string | React.ReactNode;
  variant?: ButtonVariant;
  iconPosition?: IconPosition;
  size?: ButtonSize;
  asLink?: boolean;
  className?: string;
  iconClasses?: string;
  tooltipProps?: TooltipProps;
  tooltipDisabled?: boolean;
};

type ButtonProps = CommonProps & ButtonHTMLAttributes<HTMLButtonElement>;
type AnchorProps = CommonProps & AnchorHTMLAttributes<HTMLAnchorElement>;

export type SharedButtonPropsType = ButtonProps & AnchorProps;

export const Button: React.FC<SharedButtonPropsType> = ({
  Icon,
  label,
  variant = 'tertiary',
  iconPosition = 'left',
  size = 'medium',
  asLink = false,
  className = '',
  iconClasses = '',
  tooltipProps,
  tooltipDisabled = true,
  ...props
}) => {
  const baseClasses = {
    primary: 'bg-blue-500 text-white',
    secondary: 'bg-gray-500 text-white',
    info: 'bg-green-500 text-white',
    alert: 'bg-yellow-500 text-black',
    danger: 'bg-red-500 text-white',
    tertiary: 'rounded bg-white text-gray-900',
  }[variant];

  const hoverClasses = {
    primary: 'hover:bg-blue-700 hover:text-white',
    secondary: 'hover:bg-gray-700 hover:text-white',
    info: 'hover:bg-green-700 hover:text-white',
    alert: 'hover:bg-yellow-700 hover:text-black',
    danger: 'hover:bg-red-700 hover:text-white',
    tertiary: 'hover:bg-gray-50',
  }[variant];

  const activeClasses = {
    primary: 'active:bg-blue-800 active:text-white',
    secondary: 'active:bg-gray-800 active:text-white',
    info: 'active:bg-green-800 active:text-white',
    alert: 'active:bg-yellow-800 active:text-black',
    danger: 'active:bg-red-800 active:text-white',
    tertiary: '',
  }[variant];

  const sizeClasses = {
    small: 'px-2 py-1 text-sm',
    medium: 'px-3 py-1.5 text-sm',
    large: 'px-4 py-2 text-lg',
  }[size];

  const classes = `flex items-center justify-center rounded shadow-sm ring-1 ring-inset ring-gray-300 font-semibold ${baseClasses} ${hoverClasses} ${activeClasses} ${sizeClasses} ${className}`; // Added className to the classes string

  const renderIcon = () => {
    if (Icon) {
      return <div className={`${iconClasses}`}>{Icon}</div>;
    }
    return null;
  };

  const renderButton = () =>
    asLink ? (
      <a className={`${classes}`} {...props}>
        {iconPosition === 'left' && renderIcon()}
        {label}
        {iconPosition === 'right' && renderIcon()}
      </a>
    ) : (
      <button className={`${classes}`} {...props}>
        {iconPosition === 'left' && renderIcon()}
        {label}
        {iconPosition === 'right' && renderIcon()}
      </button>
    );

  return tooltipDisabled ? (
    renderButton()
  ) : (
    <Tooltip {...tooltipProps} content={tooltipProps?.content ?? ''}>
      {renderButton()}
    </Tooltip>
  );
};
