export default async function fetchTemplate(templateName: string) {
  const fetchFile = async (fileName: string) => {
    const response = await fetch(
      `https://raw.githubusercontent.com/gitwitorg/gitwit-templates/main/${fileName}`,
    );
    return await response.text();
  };
  const fetchDirectory = async (fileName: string) => {
    console.log(`Fetching directory: ${fileName}`);
    const url = `https://api.github.com/repos/gitwitorg/gitwit-templates/contents/${fileName}`;
    const response = await fetch(url);
    const fileList = await response.json();
    const results = fileList
      .filter(({ type }: { type: string }) => type === 'file')
      .map(({ name }: { name: string }) => `${fileName}/${name}`);

    // Filter the directories from the file list first
    const directories = fileList.filter(
      ({ type }: { type: string }) => type === 'dir',
    );
    // Then process each directory sequentially
    for (const { path } of directories) {
      const directoryItems = await fetchDirectory(path);
      results.push(...directoryItems);
    }
    return results;
  };

  // Update the 'files' state with the fetched 'App.js' code
  const newFiles: { [key: string]: string } = {};
  const repositoryFiles = await fetchDirectory(templateName);
  for (const fileName of repositoryFiles) {
    const projectFileName = fileName.replace(templateName, '');
    if (projectFileName.endsWith('.png')) continue;
    newFiles[projectFileName] = await fetchFile(fileName);
  }
  return newFiles;
}
