import React from 'react';
import Spinner from '../../Spinner';

interface OverlayLoaderProps {
  loading: boolean;
  overlay?: boolean;
  children: React.ReactNode;
  loader?: React.ReactNode;
  className?: string;
  tip?: React.ReactNode;
}

export const OverlayLoader: React.FC<OverlayLoaderProps> = ({
  loading,
  overlay = false,
  children,
  loader = <Spinner />,
  className = '',
  tip,
}) => {
  if (loading && !overlay) {
    return (
      <div
        className={`w-full h-full flex flex-col items-center justify-center bg-black bg-opacity-40 animate-pulse ${className}`}
      >
        {loader}
        {tip && <div className="mt-2">{tip}</div>}
      </div>
    );
  }

  if (overlay) {
    return (
      <div className={`relative ${className}`}>
        {children}
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
            {loader}
            {tip && <div className="mt-2">{tip}</div>}
          </div>
        )}
      </div>
    );
  }

  return <>{children}</>;
};
