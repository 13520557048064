import { Ref } from 'react';

// Sandpack imports
import {
  SandpackCodeEditor,
  useSandpack,
  SandpackState,
  CodeEditorRef,
} from '@codesandbox/sandpack-react';
import { diffStripes, getAddedLineNumbers } from '../../sandpack/showStripes';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import './index.css';
import 'react-circular-progressbar/dist/styles.css';

export type History = SandpackState['files'];
export type HistoryStack = Array<History>;

type EditorProps = {
  history: HistoryStack;
  loading: boolean;
  loadingProgress: number;
  editorRef: Ref<CodeEditorRef>;
};

export const Editor = function ({
  history,
  loading,
  loadingProgress,
  editorRef,
}: EditorProps) {
  const { sandpack } = useSandpack();
  const { activeFile } = sandpack;
  const savedState: History = history[history.length - 1];
  let highlightLines: Array<number> = [];
  if (savedState) {
    const oldState = savedState[activeFile]?.code ?? '';
    const newState = sandpack.files[activeFile]?.code ?? '';
    highlightLines = getAddedLineNumbers(oldState, newState);
  }
  return (
    <div className={`relative h-full pt-2`}>
      {loading && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-10 pointer-events-none">
          <div style={{ width: 80, height: 80 }}>
            <CircularProgressbar
              value={loadingProgress}
              strokeWidth={10}
              styles={buildStyles({
                trailColor: 'rgb(241 245 249)',
                pathColor: 'rgb(63 131 248)',
              })}
            />
          </div>
        </div>
      )}
      <SandpackCodeEditor
        showTabs={false}
        showLineNumbers={true}
        /*
        showInlineErrors
        This causes an inline error when the error is on the last line and that line is deleted.
        */
        wrapContent
        style={{
          height: '100%',
          opacity: loading ? 0.8 : 1,
        }}
        readOnly={loading}
        extensions={[diffStripes({ lines: highlightLines })]}
        ref={editorRef}
      />
    </div>
  );
};
