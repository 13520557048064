import React, { ReactNode } from 'react';

export const getBillingPortalURL = (signupEmail?: string): string => {
  let URL = 'https://buy.stripe.com/6oE5kD3XnagW6xGaEF';

  if (signupEmail) {
    URL += `?prefilled_email=${encodeURIComponent(signupEmail)}`;
  }

  return URL;
};

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const deepCopy = (obj: any): any => {
  // Return non-objects as-is:
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  // Handle arrays:
  if (Array.isArray(obj)) {
    return obj.map(deepCopy);
  }
  // Recursively copy nested objects:
  const copy: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = deepCopy(obj[key]);
    }
  }
  return copy;
};

export function extractText(node: ReactNode): string {
  if (typeof node === 'string') {
    return node;
  } else if (
    React.isValidElement(node) &&
    typeof (node as React.ReactElement).props.children === 'string'
  ) {
    return (node as React.ReactElement).props.children;
  } else {
    return '';
  }
}
