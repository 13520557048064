import React, { useMemo, useState } from 'react';
import { SignInOutButton } from './ClerkButton';
import { buttonStyles } from '../constants/toolbar';
import { useClerk } from '@clerk/clerk-react';
import { Button } from './shared/Button';

export const UserDropdown = ({
  currentProjectId,
}: {
  currentProjectId?: string | null;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useClerk();
  const userSignedIn: boolean = !!user && !!user.id;

  const initials = useMemo(() => {
    if (!(user?.firstName && user?.lastName)) {
      return '👋🏻';
    }
    return `${user?.firstName?.[0]}${user?.lastName?.[0]}`;
  }, [user]);

  const displayName = useMemo(() => {
    if (!(user?.firstName && user?.lastName)) {
      return user?.primaryEmailAddress?.emailAddress;
    }
    return `${user?.firstName} ${user?.lastName}`;
  }, [user]);

  if (!userSignedIn)
    return (
      <SignInOutButton
        className={buttonStyles}
        afterSignInUrl={
          // This is the local storage project ID
          currentProjectId ? `/signin/${currentProjectId}` : undefined
        }
      />
    );

  return (
    <div className="relative inline-block text-left">
      <Button
        type="button"
        className="inline-flex items-center justify-center gap-x-1.5 rounded-full !bg-blue-500 !text-white w-8 h-8 text-sm font-semibold shadow-sm ring-1 ring-inset !ring-gray-300 !hover:bg-blue-600"
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        onClick={() => setIsOpen(!isOpen)}
        label={initials}
      />

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 min-w-56 w-[max-content] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div className="text-gray-700 block px-4 py-2 text-sm">
              Hey, {displayName}
            </div>
            {/* Sign in/out Button */}
            <SignInOutButton className="text-gray-700 block px-4 py-2 text-sm w-full text-left" />
          </div>
        </div>
      )}
    </div>
  );
};
