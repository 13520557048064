import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

// Custom hook to manage user cookies
export function useUserCookie(cookieName = 'userId', expirationDays = 365) {
  const [userId, setUserId] = useState<string | null>(null);
  const [firstLaunch, setFirstLaunch] = useState<boolean>(false);

  useEffect(() => {
    const existingCookie = Cookies.get(cookieName);

    if (!existingCookie) {
      const cookieValue = uuidv4();

      // Set the cookie with an expiration date 1 year from now
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 365);

      Cookies.set(cookieName, cookieValue, { expires: expirationDate });
      setUserId(cookieValue); // Store the user ID in the state
      setFirstLaunch(true);
      console.log('Cookie set:', cookieValue);
    } else {
      setUserId(existingCookie); // Use the existing user ID
      console.log('Cookie already exists:', existingCookie);
    }
  }, [cookieName, expirationDays]);

  // If the user ID hasn't loaded yet, return an empty string.
  // A null value will cause code generation requests to fail.
  return { userId: userId ?? '', firstLaunch };
}
