import { Fragment, useState, useRef, useEffect } from 'react';
import { Combobox, Dialog, Transition } from '@headlessui/react';

export interface Command {
  id?: string;
  name: string;
}

interface CommandBarProps {
  open: boolean;
  callback?: (commandId?: string, query?: string) => void;
  commands: (query?: string) => Command[];
  filename?: string;
}

function classNames(...classes: (string | boolean)[]): string {
  return classes.filter(Boolean).join(' ');
}

export function CommandBar({
  open,
  commands,
  callback,
  filename,
}: CommandBarProps): JSX.Element {
  const [query, setQuery] = useState<string>('');
  const commandList = commands(query);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    // Keep the input focused when the query changes.
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [query]);

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setQuery('')}
      appear
    >
      <Dialog
        as="div"
        className="relative z-20"
        onClose={() => {
          callback && callback();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all m-auto md:mt-0 mt-8">
              {
                <Combobox
                  onChange={(commandId: string) => {
                    callback && callback(commandId, query || commandId);
                  }}
                >
                  <Combobox.Input
                    ref={inputRef}
                    className="w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 focus:ring-0 sm:text-m"
                    placeholder={`Make changes to ${filename}...`}
                    onChange={(event) => setQuery(event.target.value)}
                    value={query}
                  />

                  <Combobox.Options
                    static
                    className="-mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
                  >
                    {commandList.map((command) => (
                      <Combobox.Option
                        key={command.id || command.name}
                        value={command.id || command.name}
                        className={({ active }) =>
                          classNames(
                            'cursor-default select-none rounded-md px-4 py-2',
                            active && 'bg-blue-500 text-white',
                          )
                        }
                      >
                        {command.name}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </Combobox>
              }
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
