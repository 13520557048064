import { useEffect, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
// Sandpack imports
import { SandpackPreview, useSandpack } from '@codesandbox/sandpack-react';
import { loadFiles } from '../sandpack/loadFiles';

// Hooks
import { useProjects } from '../hooks/projects';

export const ProjectPreview = function ({
  urlProjectId,
}: {
  urlProjectId: string | null;
}) {
  const [loading, setLoading] = useState<boolean>(true);
  // Get the project data from the API.
  const { projects, getVersion, error } = useProjects({
    projectId: urlProjectId,
  });

  // Load the project files into the editor.
  const { sandpack } = useSandpack();
  useEffect(() => {
    async function fetchData() {
      if (projects && urlProjectId && projects[urlProjectId]) {
        console.log(`Loading ${urlProjectId} from URL.`);
        // Load the project files.
        const loadedFiles = await getVersion(projects[urlProjectId]['version']);
        if (loadedFiles) loadFiles(loadedFiles, sandpack);
        setLoading(false);
      }
    }
    fetchData();
  }, [projects]);

  const previewProps = {
    style: {
      height: '100%',
    },
    showOpenInCodeSandbox: false,
    showSandpackErrorOverlay: false,
  };

  if (error) {
    return (
      <div className="flex h-screen w-screen items-center justify-center bg-red-100">
        <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4">
          <div className="flex-shrink-0">
            <FiAlertCircle className="h-12 w-12 text-red-500" />
          </div>
          <div>
            <div className="text-xl font-medium text-black">
              Oops! An error occurred
            </div>
            <p className="text-gray-500">
              {error instanceof Error ? error.message : JSON.stringify(error)}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      {loading ? (
        <p className="text-xl text-center">Loading...</p>
      ) : (
        <SandpackPreview {...previewProps} />
      )}
    </div>
  );
};
