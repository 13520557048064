import { FC, ReactNode } from 'react';
import { Button, SharedButtonPropsType } from './shared/Button';
import { extractText } from '../utils';
interface ToolbarButtonProps extends SharedButtonPropsType {
  label: string | ReactNode;
  icon?: ReactNode;
}

export const ToolbarButton: FC<ToolbarButtonProps> = ({
  label,
  icon,
  ...restProps
}) => {
  return (
    <Button
      Icon={icon}
      label={label}
      className="-mb-1 inline -translate-y-1"
      {...restProps}
      tooltipDisabled={false}
      tooltipProps={{
        content: extractText(label),
      }}
      iconClasses={'!mr-0'}
    />
  );
};
