import { RefObject } from 'react';
import { SandpackState, CodeEditorRef } from '@codesandbox/sandpack-react';

type UseStaticTransformationOptions = {
  sandpack: SandpackState;
  editorRef: RefObject<CodeEditorRef>;
  onStart: () => void;
};

// Helper function to replace a range of characters in a string.
const replaceRange = (
  s: string,
  start: number,
  end: number,
  substitute: string,
): string => s.substring(0, start) + substitute + s.substring(end);

export const useStaticTransformation = (
  transformationOptions: UseStaticTransformationOptions,
) => {
  const { sandpack, editorRef, onStart } = transformationOptions;

  // Get the selected range of characters in the editor.
  const getSelectedRange = () => {
    const editorElement = editorRef.current?.getCodemirror();
    if (!editorElement) return null;
    const from: number | undefined =
      editorElement.state.selection.ranges[0].from;
    const to: number | undefined = editorElement.state.selection.ranges[0].to;
    if (from === undefined || to === undefined || from === to) return null;
    return { from, to };
  };

  // Set the selected range of characters in the editor.
  const setSelectedRange = (from: number, to: number) => {
    const editorElement = editorRef.current?.getCodemirror();
    if (!editorElement) return;
    const newState = editorElement.state.update({
      selection: {
        anchor: from,
        head: to,
      },
      scrollIntoView: true,
    });
    if (newState) {
      editorElement?.update([newState]);
    }
  };

  // Get the selected text in the editor.
  const getSelectedText = () => {
    const { from, to } = getSelectedRange() ?? {};
    if (from === undefined || to === undefined) return null;
    const activeFileContents = sandpack.files[sandpack.activeFile].code;
    return activeFileContents.substring(from, to);
  };

  // Replace the selected text in the editor.
  const replaceSelectedText = (replacementText: string) => {
    const { from, to } = getSelectedRange() ?? {};
    if (from === undefined || to === undefined) return;
    const activeFileContents = sandpack.files[sandpack.activeFile].code;
    sandpack.updateCurrentFile(
      replaceRange(activeFileContents, from, to, replacementText),
    );
    setSelectedRange(from, from + replacementText.length);
  };

  // Get the name of the first selected React component definition.
  const getSelectedComponentName = () => {
    // Get the active file's selected text.
    const selectionContents = getSelectedText();
    if (!selectionContents) return null;

    // Get the selected component name.
    const componentName =
      selectionContents.match(/function\s+(\w+)/)?.[1] ||
      selectionContents.match(/const\s+(\w+)/)?.[1];
    if (!componentName) return null;
    return componentName;
  };

  // Move the selected React component definition to a new file named after the component.
  const moveSelectedComponentToNewFile = () => {
    const componentName = getSelectedComponentName();
    const selectionContents = getSelectedText();
    if (!componentName || !selectionContents) return;

    if (
      sandpack.files[`/src/components/${componentName}.js`] &&
      sandpack.files[`/src/components/${componentName}.js`].code.length
    ) {
      alert(`The file /components/${componentName}.js already exists.`);
      return;
    }

    onStart();

    // Generate the new file with the component definition.
    const activeFileContents = sandpack.files[sandpack.activeFile].code;
    const allImports = activeFileContents.match(
      /^import\s+(?:.*?\s+from\s+)?['"]([^\/\.].*)['"];?\s*(?:\/\/.*)?$/gm,
    );
    // Add "export default" to the component definition.
    const componentDefinition = selectionContents.replace(
      /(function\s+(\w+))|(const\s+(\w+)\s+=)/,
      (match, funcMatch, funcName, constMatch) =>
        funcMatch
          ? `export default function ${componentName}`
          : constMatch
          ? `export default ${componentName} =`
          : '',
    );
    // If there are any import statements in the active file, add them to the new file.
    const newFileCode = allImports
      ? allImports.join('\n') + '\n\n' + componentDefinition
      : componentDefinition;
    sandpack.addFile(`/src/components/${componentName}.js`, newFileCode);

    // Replace the component definition in the active file with an import statement.
    const replacementText = `import ${componentName} from "./components/${componentName}.js";`;
    replaceSelectedText(replacementText);
  };

  return {
    moveSelectedComponentToNewFile,
    getSelectedComponentName,
  };
};
