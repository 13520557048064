import React, { useState, useEffect } from 'react';
import { MdModeEdit, MdSave } from 'react-icons/md';
import { Tooltip } from 'flowbite-react';

interface EditableTextProps {
  value: string;
  onChange: (value: string) => void;
  onFocus: () => void;
}

export default function EditableText({
  value,
  onChange,
  onFocus = () => {},
}: EditableTextProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState<string>(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleClick = () => {
    setIsEditing(true);
    onFocus();
  };

  const handleBlur = () => {
    onChange(currentValue);
    setIsEditing(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
      onChange(currentValue);
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setCurrentValue(value);
    }
  };

  return (
    <>
      {isEditing ? (
        <div className="flex items-center">
          <input
            type="text"
            className="tracking-tight border border-gray-200 p-0.5 bg-transparent flex-grow"
            value={currentValue}
            autoFocus
            onChange={handleInputChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            maxLength={256} // limit input to 256 characters
          />
          <Tooltip content="Save project name" placement="right" style="light">
            <MdSave
              className="ml-2 cursor-pointer text-black"
              onClick={handleBlur}
              aria-label="Save"
            />
          </Tooltip>
        </div>
      ) : (
        <div className="flex items-center relative ">
          <Tooltip content={value ?? ''}>
            <span
              className="inline-block tracking-tight cursor-text select-none border border-transparent hover:border-gray-200 p-0.5 flex-grow overflow-hidden overflow-ellipsis whitespace-nowrap w-full" // handle long text
              style={{ maxWidth: '300px' }} // apply a max width of 300px
              onClick={handleClick}
            >
              {currentValue}
            </span>
          </Tooltip>
          <Tooltip content="Edit project name" placement="right" style="light">
            <MdModeEdit
              className="ml-2 mb-2 cursor-pointer text-black"
              onClick={handleClick}
              aria-label="Edit"
              size={19}
            />
          </Tooltip>
        </div>
      )}
    </>
  );
}
