import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from 'firebase/firestore/lite';
import { firebaseConfig } from '../config/firebase';
import { UserType } from '../utils/types';
import { useClerk } from '@clerk/clerk-react';

// Initialize Firebase app with the provided configuration
const app = initializeApp(firebaseConfig);

// Function to fetch the user from the 'users' collection in Firebase
const fetchUser = async (email: string): Promise<UserType | null> => {
  const db = getFirestore(app);
  const userDoc = await getDoc(doc(db, 'users', email));
  if (userDoc.exists()) {
    return userDoc.data() as UserType;
  }
  return null;
};

export const useFirebaseUser = () => {
  const { user, loaded } = useClerk();
  const [fetchedUser, setFetchedUser] = useState<UserType | null>(null);
  const [error, setError] = useState<unknown>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const fetchedUser = await fetchUser(
          user?.emailAddresses?.[0]?.emailAddress ?? '',
        );
        setFetchedUser(fetchedUser);
      } catch (err: unknown) {
        console.log(err);
        setError(err);
      } finally {
        if (loaded) setLoading(false);
      }
    };
    fetchUserData();
  }, [user]);

  return { user: fetchedUser, error, loading };
};
