import { useState, useCallback } from 'react';

// Creates a timer that calls the callback function after the specified duration.
// The timer is cleared if startTimer is called again before the timer expires.
export const useTimer = (duration: number, callback: () => void) => {
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  const startTimer = useCallback(() => {
    if (timerId) {
      clearTimeout(timerId);
      setTimerId(null);
    }
    const id = setTimeout(() => {
      callback();
      setTimerId(null);
    }, duration);
    setTimerId(id);
  }, [timerId, callback]);

  return { startTimer };
};

export default useTimer;
