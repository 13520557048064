import { RangeSetBuilder, Extension } from '@codemirror/state';
import {
  EditorView,
  ViewPlugin,
  Decoration,
  DecorationSet,
  ViewUpdate,
} from '@codemirror/view';

const stripe = Decoration.line({
  attributes: { class: 'cm-newLine' },
});

function stripeDeco(view: EditorView, lines: Array<number>) {
  const builder = new RangeSetBuilder<Decoration>();
  for (const { from, to } of view.visibleRanges) {
    for (let pos = from; pos <= to; ) {
      const line = view.state.doc.lineAt(pos);
      if (lines.includes(line.number - 1)) {
        builder.add(line.from, line.from, stripe);
      }
      pos = line.to + 1;
    }
  }
  return builder.finish();
}

const baseTheme = EditorView.baseTheme({
  '&light .cm-newLine': { backgroundColor: '#d7fcdf' },
  '&light .cm-activeLine.cm-newLine': { backgroundColor: '#c1f5cd !important' },
  '&dark .cm-newLine': { backgroundColor: '#273825 !important' },
});

export function diffStripes(
  options: { step?: number; lines?: Array<number> } = {},
): Extension {
  const lines = options.lines ?? [];

  const showStripes = ViewPlugin.fromClass(
    class {
      decorations: DecorationSet;

      constructor(view: EditorView) {
        this.decorations = stripeDeco(view, lines);
      }

      update(update: ViewUpdate) {
        if (update.docChanged || update.viewportChanged)
          this.decorations = stripeDeco(update.view, lines);
      }
    },
    {
      decorations: (v) => v.decorations,
    },
  );

  return [baseTheme, showStripes];
}

export const getAddedLineNumbers = (text1: string, text2: string): number[] => {
  const lines1: string[] = text1.split('\n');
  const lines2: string[] = text2.split('\n');
  const addedLineNumbers: number[] = [];

  for (let i = 0, j = 0; j < lines2.length; j++) {
    if (lines1[i] !== lines2[j]) {
      if (lines1.slice(i).includes(lines2[j]))
        // One or more lines have been removed—skip ahead.
        i = i + lines1.slice(i).indexOf(lines2[j]);
      // A line has been added.
      else addedLineNumbers.push(j);
    } else {
      // The two lines are the same.
      i++;
    }
  }

  return addedLineNumbers;
};
