import * as ReactDOMClient from 'react-dom/client';

import App from './App';
import './styles.css';

import { ClerkProvider } from '@clerk/clerk-react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const rootElement = document.getElementById('root');
const root = ReactDOMClient.createRoot(rootElement!);

const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <ClerkProvider publishableKey={clerkPubKey}>
        <App />
      </ClerkProvider>
    ),
  },
]);

root.render(<RouterProvider router={router} />);
